/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { encode, decode } from 'ee-crypto-encode-decode';
import { environment } from 'src/environments/environment';
import { CachingService } from 'src/app/services/cache/caching.service';
import { APIOptions } from 'src/app/utils/interfaces';
import { StorageEnums } from 'src/app/utils/storage.enums';
declare const window: any;

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private isEncrypted = environment.isEncrypted;
  defaultAPIOptions: APIOptions = {
    version: environment.apiVersion,
    lang_code: environment.languageCode,
  }
  constructor(
    private httpClient: HttpClient,
    private cachingService: CachingService,
  ) { }

  /**
   *
   * @returns
   * The code is trying to authenticate the user by sending them a token.
   * It's also using sessionStorage to store the access-token.
   */
  authenticate() {
    return new Promise((resolve, reject) => {
      const json = environment.loginPayload;
      const reqBody = this.isEncrypted ? encode(json) : json;
      return this.httpClient
        .post(environment.apiUrl + 'api/v1/login ', reqBody)
        .toPromise()
        .then(
          (data: any) => {
            sessionStorage.setItem('access-token', data.response.token);
            resolve(true);
          },
          (error) => { }
        );
    });
  }

  /**
   *
   * @param token The code is trying to validate a token.
   * The code is sending the token and an encrypted body to the server.
   * If the server responds with success, then it will authenticate itself.
   * Otherwise, it will log any errors that occurred in its console and continue on as normal.
   * @returns
   */
  validateToken(token) {
    let reqBody = { token };
    reqBody = this.isEncrypted ? encode(reqBody) : reqBody;
    return this.httpClient
      .post(environment.apiUrl + 'api/verify-token', reqBody)
      .toPromise()
      .then(
        (res: any) => {
          if (res.message !== 'success') {
            this.authenticate();
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }
  /**
   * The code is a promise that will return the data from the JSON file.
   *
   * @param jsonName The Json to fetch
   * @returns
   */
  async getDataFromJSON(jsonName: string) {
    return new Promise((resolve, reject) => {
      this.httpClient
        .get<any[]>(`assets/JSON/${jsonName}.json`, {
          observe: 'response',
        })
        .subscribe(
          async (response: any) => {
            resolve(response.body);
          },
          async (error) => {
            reject(error);
          }
        );
    });
  }
  /**
   * @param endPoint
   * @param requestBody
   * The code is a function that takes in an endpoint and request body.
   * It then returns a promise which is resolved with the response or rejected with any error.
   * The code first checks if the request is encrypted by checking if it's property "isEncrypted" is true.
   * If so, it will encode the request body before sending to the server and
   * return a promise that resolves with JSON data parsed from the response body.
   * Otherwise, it sends out without encoding and resolves when successful or rejects on errors
   * @returns
   */
  post(endPoint: string, requestBody: any, _options: APIOptions = this.defaultAPIOptions) {
    // requestBody['lang_code'] = 'en-US';
    const options = { ...this.defaultAPIOptions, ..._options };
    requestBody = { ...requestBody, lang_code: options.lang_code };
    const request = this.isEncrypted ? encode(requestBody) : requestBody;
    return new Promise((resolve, reject) => {
      this.httpClient
        .post<any>(environment.apiUrl + `api/v${options.version}/` + endPoint, request, {
          observe: 'response',
        })
        .subscribe(
          async (response: any) => {
            if (this.isEncrypted) {
              resolve(JSON.parse(decode(response.body)));
            } else {
              resolve(response.body);
            }
          },
          async (error) => {
            reject(error);
          }
        );
    });
  }

  /**
   * @param endPoint
   * @param requestBody
   * The code is a function that takes in an endpoint and request body.
   * It then returns a promise which is resolved with the response or rejected with any error.
   * The code first checks if the request is encrypted by checking if it's property "isEncrypted" is true.
   * If so, it will encode the request body before sending to the server and
   * return a promise that resolves with JSON data parsed from the response body.
   * Otherwise, it sends out without encoding and resolves when successful or rejects on errors
   * @returns
   */
  put(endPoint: string, requestBody: any, _options: APIOptions = this.defaultAPIOptions) {
    // requestBody['lang_code'] = 'en-US';
    const options = { ...this.defaultAPIOptions, ..._options };
    requestBody = { ...requestBody, lang_code: options.lang_code };
    const request = this.isEncrypted ? encode(requestBody) : requestBody;
    return new Promise((resolve, reject) => {
      this.httpClient
        .put<any>(environment.apiUrl + `api/v${options.version}/` + endPoint, request, {
          observe: 'response',
        })
        .subscribe(
          async (response: any) => {
            if (this.isEncrypted) {
              resolve(JSON.parse(decode(response.body)));
            } else {
              resolve(response.body);
            }
          },
          async (error) => {
            reject(error);
          }
        );
    });
  }

  /**
   * @param endPoint
   * @param requestBody
   * The code is a function that takes in an endpoint and request body.
   * It then returns a promise which is resolved with the response or rejected with any error.
   * The code first checks if the request is encrypted by checking if it's property "isEncrypted" is true.
   * If so, it will encode the request body before sending to the server and
   * return a promise that resolves with JSON data parsed from the response body.
   * Otherwise, it sends out without encoding and resolves when successful or rejects on errors
   * @returns
   */
  get(endPoint: string, requestBody: any, _options: APIOptions = this.defaultAPIOptions) {
    // requestBody['lang_code'] = 'en-US';
    const options = { ...this.defaultAPIOptions, ..._options };
    requestBody = { ...requestBody, lang_code: options.lang_code };
    const request = this.isEncrypted ? encode(requestBody) : requestBody;
    return new Promise((resolve, reject) => {
      this.httpClient
        .get<any>(environment.apiUrl + `api/v${options.version}/` + endPoint, { params: request })
        .subscribe(
          async (response: any) => {
            if (this.isEncrypted) {
              resolve(JSON.parse(decode(response)));
            } else {
              resolve(response);
            }
          },
          async (error) => {
            reject(error);
          }
        );
    });
  }
  /**
   * @param endpoint The code is a function that downloads the file from an endpoint.
   * @returns
   */
  download(endpoint: string, _options: APIOptions = this.defaultAPIOptions) {
    const options = { ...this.defaultAPIOptions, ..._options };
    const httpOptions = {
      responseType: 'blob' as 'json',
    };

    return this.httpClient.get(
      environment.apiUrl + `api/v${options.version}/` + endpoint,
      httpOptions
    );
  }
  /**
   * The code is a function that takes in an event and then pushes the data to the data layer.
   * The code is called when there is an event on the window object.
   * It will be called when the user clicks on an event.
   *
   * @param event The first parameter of the function is an event object which contains information about what happened in the application.
   * @param eventCategory The second parameter is a string representing the category of events that this event falls under.
   * @param eventAction The third parameter is a string representing what action was taken by the user.
   * @param eventLabel The final parameter is a string with some text describing what happened to help identify why this event occurred.
   */
  logGAEvent(event, eventCategory, eventAction, eventLabel) {
    // if not mobile device & includes ::Set_ in string then don't push it to datalayer
    if (eventAction.includes('::Set_') && !this.isMobileDevice()) {
      return;
    } else {
      window.dataLayer.push({
        event,
        eventCategory,
        eventAction,
        eventLabel,
      });
    }
  }
  async visualizerAnalytics(eventName, eventValue?) {
    const pinNo = await this.cachingService.getDataFromSession(StorageEnums.PIN_NO);
    const model = await this.cachingService.getDataFromSession(StorageEnums.SELECTED_MODEL);
    const config_id = await this.cachingService.getDataFromSession(StorageEnums.CONFIGURATION_ID);

    const request = {
      configuration_id: config_id,
      pin_no: pinNo[model.lms_integrated_code],
      action: eventName,
      value: eventValue ? eventValue : 'Yes'
    };
    this.post('configuration/journey', request)
      .then((res) => {
        console.log('Visualizer analytics captured');
      })
      .catch((err) => {
        console.error('Error ', err);
      });
  }

  isMobileDevice() {
    const ua = navigator.userAgent;
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  sendReportingData(data: any) {
    window.sendData(data);
  }
  updateReportingData(key: string, value: any) {
    window.updatePayloadParameter(key, value);
  }
  updateMultipleReportingData(data: any) {
    window.updateMultiplePayloadParameter(data);
  }
}
