import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'visualizer/:zipCode/:vehicleCode/:cpos/:optionalCodes',
    loadChildren: () =>
      import('./pages/visualizer/visualizer.module').then(
        (m) => m.VisualizerPageModule
      ),
  },
  {
    path: 'visualizer/:zipCode/:vehicleCode/:cpos',
    loadChildren: () =>
      import('./pages/visualizer/visualizer.module').then(
        (m) => m.VisualizerPageModule
      ),
  },
  {
    path: 'visualizer/:zipCode/:vehicleCode',
    loadChildren: () =>
      import('./pages/visualizer/visualizer.module').then(
        (m) => m.VisualizerPageModule
      ),
  },
  {
    path: 'visualizer/:zipCode',
    loadChildren: () =>
      import('./pages/visualizer/visualizer.module').then(
        (m) => m.VisualizerPageModule
      ),
  },
  {
    path: 'visualizer',
    loadChildren: () =>
      import('./pages/visualizer/visualizer.module').then(
        (m) => m.VisualizerPageModule
      ),
  },
  {
    path: 'emaginator/:zipCode/:vehicleCode/:cpos/:optionalCodes',
    loadChildren: () =>
      import('./pages/emaginator/emaginator.module').then(
        (m) => m.EmaginatorPageModule
      ),
  },
  {
    path: 'emaginator/:zipCode/:vehicleCode/:cpos',
    loadChildren: () =>
      import('./pages/emaginator/emaginator.module').then(
        (m) => m.EmaginatorPageModule
      ),
  },
  {
    path: 'emaginator/:zipCode/:vehicleCode',
    loadChildren: () =>
      import('./pages/emaginator/emaginator.module').then(
        (m) => m.EmaginatorPageModule
      ),
  },
  {
    path: 'emaginator/:zipCode',
    loadChildren: () =>
      import('./pages/emaginator/emaginator.module').then(
        (m) => m.EmaginatorPageModule
      ),
  },
  {
    path: 'emaginator',
    loadChildren: () =>
      import('./pages/emaginator/emaginator.module').then(
        (m) => m.EmaginatorPageModule
      ),
  },
  {
    path: ':zipCode/:vehicleCode/:cpos/:optionalCodes',
    loadChildren: () =>
      import('./pages/home/home.module').then(
        (m) => m.HomePageModule
      ),
  },
  {
    path: ':zipCode/:vehicleCode/:cpos',
    loadChildren: () =>
      import('./pages/home/home.module').then(
        (m) => m.HomePageModule
      ),
  },
  {
    path: ':zipCode/:vehicleCode',
    loadChildren: () =>
      import('./pages/home/home.module').then(
        (m) => m.HomePageModule
      ),
  },
  {
    path: ':zipCode',
    loadChildren: () =>
      import('./pages/home/home.module').then(
        (m) => m.HomePageModule
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./pages/home/home.module').then(
        (m) => m.HomePageModule
      ),
  },
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
