import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { encode, decode } from 'ee-crypto-encode-decode';

@Injectable({
  providedIn: 'root',
})
export class CachingService {
  encryptData = true;
  constructor() { }

  async setDataInLocal(key: string, data: any) {
    const dataToStore = this.encryptData ? encode(data) : JSON.stringify(data);
    await Preferences.set({ key, value: dataToStore });
  }
  setDataInSession(key: string, data: any) {
    const dataToStore = this.encryptData ? encode(data) : JSON.stringify(data);
    sessionStorage.setItem(key, dataToStore);
  }

  async getDataFromLocal(key: string) {
    const data = await Preferences.get({ key });
    if (data.value != null && data.value !== '') {
      const decryptedData = this.encryptData
        ? decode(JSON.parse(data.value))
        : JSON.parse(data.value);
      return decryptedData;
    } else {
      return null;
    }
  }

  getDataFromSession(key: string) {
    const data = sessionStorage.getItem(key);
    if (data != null && data !== '') {
      const decryptedData = this.encryptData
        ? decode(JSON.parse(data))
        : JSON.parse(data);
      return decryptedData;
    } else {
      return null;
    }
  }

  async removeDataFromLocal(key: string) {
    await Preferences.remove({ key });
  }
  async removeDataFromSession(key: string) {
    sessionStorage.removeItem(key);
  }
}
