export enum StorageEnums {
    SELECTED_MODEL = 'sm_',
    PIN_NO = 'pn_',
    OLD_CONFIGURATION = 'oc_',
    VARIANTS = 'vs_',
    VARIANT_DETAILS = 'vd_',
    MODEL_DETAILS = 'md_',
    CONFIGURATION_ID = 'cid_',
    UTM_PARAMS = 'utmp_',
    CONFIG = 'config_',
    BUILD_NAME = 'bn_',
    VISITOR_ID = 'vid_',
    MUTE_MUSIC = 'mm_',
    SELECTED_COLOR = 'sc_',
    EMI_DETAILS = 'emid_',
    INTERIOR_TUTORIAL = 'it_',
    TUTORIAL_DISPLAYED = 'td_',
    VISUALIZER_TUTORIAL_DISPLAYED = 'vtd_',
    MODEL_LOADED = 'ml_',
    MUTE_FEATURE_AUDIO = 'mfa_',
    DISCOVER_MORE_TOOLTIP = 'dmt_',
    SECTIONS_TUTORIAL_DISPLAYED = 'sectd_',
    POSTCODE = 'pcode_',
    IS_CONFIGURATOR = 'iscc_',
}
