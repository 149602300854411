import { Component, OnInit } from '@angular/core';
import { EventService } from './services/Events/events.service';
import { GlobalService } from './services/global/global.service';

declare const window: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private readonly events: EventService,
    private readonly globalService: GlobalService
  ) {}

  ngOnInit() {
    this.events.subscribe('events', (data) => {
      this.subscribeToEvents(data);
    });
  }

  subscribeToEvents(data: any) {
    const key = Object.keys(data)[0];
    switch (key) {
      case 'showLoader':
        this.globalService.isLoading = true;
        break;
      case 'hideLoader':
        this.globalService.isLoading = false;
        break;
      case 'rotateModal':
        setTimeout(() => {
          this.one3dOrientationBlock();
        }, 10);
        break;
      default:
        break;
    }
  }

  // ---- Orientation Code Starts ----
  portraitView() {
    window.onresize = this.portraitWindowSize();
    window.onload = this.portraitWindowSize();
  }
  portraitWindowSize() {
    const getDeviceWidth = window.innerWidth;
    const getDeviceHeight = window.innerHeight;
    const emaginatorUiComponent: HTMLElement = document.getElementById(
      'leapUIModal'
    ) as HTMLElement;
    if (emaginatorUiComponent) {
      emaginatorUiComponent.style.transform = 'rotate(90deg)';
      emaginatorUiComponent.style.margin =
        '0px 0px 0px ' + getDeviceWidth + 'px';
      emaginatorUiComponent.style.width = getDeviceHeight + 'px';
      emaginatorUiComponent.style.height = getDeviceWidth + 'px';
      emaginatorUiComponent.style.transformOrigin = '0px 0px 0px';
      window.dispatchEvent(new Event('resize'));
    }
  }
  landscapeView() {
    window.onresize = this.landscapeWindowSize();
    window.onload = this.landscapeWindowSize();
  }
  landscapeWindowSize() {
    const getDeviceWidth = window.innerWidth;
    const getDeviceHeight = window.innerHeight;
    const emaginatorUiComponent: HTMLElement = document.getElementById(
      'leapUIModal'
    ) as HTMLElement;
    if (emaginatorUiComponent) {
      emaginatorUiComponent.style.transform = 'rotate(0deg)';
      emaginatorUiComponent.style.margin = '0px auto';
      emaginatorUiComponent.style.width = getDeviceWidth + 'px';
      emaginatorUiComponent.style.height = getDeviceHeight + 'px';
      emaginatorUiComponent.style.transformOrigin = 'initial';
      window.dispatchEvent(new Event('resize'));
    }
  }
  //  Detect Operating Systems of Mobile Devices
  getMobileSystem() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }
    if (/android/i.test(userAgent)) {
      return 'Android';
    }
    // iOS detection for iPhones and iPods
    if (/iPhone|iPod/.test(userAgent)) {
      return 'iOS';
    }
    // iOS detection for iPads
    if (/iPad|Safari|Apple/i.test(userAgent)) {
      return 'iPad';
    }
    return 'unknown';
  }
  // Apply Orientation change
  one3dOrientationBlock() {
    const device = this.getMobileSystem(); // Windows Phone,Android, iOS, iPad, unknown
    //  Condition to apply orientation as per devices - iPad, Android, iOS
    switch (device) {
      case 'iPad':
        if (window.matchMedia('(orientation: portrait)').matches) {
          this.portraitView();
        } else {
          this.landscapeView();
        }
        break;
      case 'Android':
        if (window.matchMedia('(orientation: portrait)').matches) {
          this.portraitView();
        } else {
          this.landscapeView();
        }
        break;
      case 'iOS':
        if (window.matchMedia('(orientation: portrait)').matches) {
          this.portraitView();
        } else {
          this.landscapeView();
        }
        break;
      default:
        this.landscapeView();
        break;
    }
  }
  // ---- Orientation Code Ends ----
}
