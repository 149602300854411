export enum AppSections {
  Emaginator = 'Emaginator',
  Visualizer = 'Visualizer',
  Configurator = 'Configurator',
}

export enum ConfigurationTypes {
  Old = 'old',
  New = 'new',
}

export enum StatusTypes {
  Yes = 'yes',
  No = 'no',
}

export enum TutorialType {
  Tap = 'tap',
  Pinch = 'pinch',
}

export enum Tabs {
  KITS = 'KITS',
  EXTERIOR = 'EXTERIOR',
  INTERIOR = 'INTERIOR',
}

export enum SubSectionEnum {
  SUB_SECTION = 'sub_section',
  SUB_OPTION = 'sub_option',
}

export enum SummaryTabs {
  MODEL_DETAILS = 'model_details',
  PRICE_BREAKUP = 'price_breakup',
  ADDED_ACCESSORIES = 'added_accessories',
  OFFERS = 'offers',
}
